import React from "react";
import { useQuery } from "@apollo/client";
import { Button } from "@xometry/xometry_loft";
import { prismicClient } from "../../apollo";
import {
  GetAnnouncementBannerQuery,
  GetAnnouncementBannerQueryResult,
} from "../../schema/generated/prismic-schema";
import {
  announcementBannerBackground,
  announcementBannerTitles,
  announcementBannerContent,
  announcementBannerTitle,
  announcementBannerSubtitle,
  announcementBannerCTA,
} from "./AnnouncementBanner.style";
import { track } from "../../utils/analytics";

const AnnouncementBanner = () => {
  const { data } = useQuery<GetAnnouncementBannerQueryResult>(
    GetAnnouncementBannerQuery,
    {
      client: prismicClient,
    }
  );

  // Grab the first entry since announcement banner is a singleton type in Prismic
  const banner = data?.allAnnouncement_banners?.edges?.[0]?.node;

  return (
    (banner && (
      <div css={announcementBannerBackground}>
        <div css={announcementBannerContent}>
          <div css={announcementBannerTitles}>
            <span css={announcementBannerTitle}>{banner.title}</span>
            <span css={announcementBannerSubtitle}>{banner.subtitle}</span>
          </div>
          {banner.cta_text && banner.cta_url?.url && (
            <Button
              kind="primary"
              css={announcementBannerCTA}
              onClick={() => {
                track("Announcement Banner", {
                  title: banner.title,
                  subtitle: banner.subtitle,
                  ctaText: banner.cta_text,
                });
                window.open(banner.cta_url?.url, "_blank");
              }}
            >
              {banner.cta_text}
            </Button>
          )}
        </div>
      </div>
    )) || <></>
  );
};

export default AnnouncementBanner;
