/* eslint-disable no-underscore-dangle */
import { ApolloClient, HttpLink, from, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { PrismicLink } from "apollo-link-prismic";

import { prismicAccessToken, prismicDomain, origins } from "./utils/constants";

/**
 * Use a federated graphql service uri provided as an environment variable or default to the
 * mock graphql api url -> localhost:8001. We will want this in a variable eventually too.
 */

export default new ApolloClient({
  link: from([
    onError(({ graphQLErrors }) => {
      if (graphQLErrors && graphQLErrors[0].extensions) {
        const { code } = graphQLErrors[0].extensions;
        if (code === "UNAUTHENTICATED") {
          // Always require login to view this app
          window.location.href = `${origins.webapp}/login`;
        }
      }
    }),
    new HttpLink({
      uri:
        window.__env__.REACT_APP_GRAPHQL_URL || "http://xometry.test:3000/graphql",
      credentials: "include",
    }),
  ]),
  cache: new InMemoryCache(),
});

// Set up Prismic client
export const prismicClient = new ApolloClient({
  link: PrismicLink({
    uri: prismicDomain,
    accessToken: prismicAccessToken,
  }),
  cache: new InMemoryCache(),
});
