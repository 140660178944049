import { css } from "@emotion/react";
import { theme } from "@xometry/xometry_loft";

export const announcementBannerBackground = css`
  background: linear-gradient(135.48deg, #1771f1 0%, #5195f5 100%);
  min-height: 48px;
  top: 0;
`;

export const announcementBannerContent = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
`;

export const announcementBannerTitles = css`
  color: #ffffff;
  font-size: 13px;
  line-height: 18px;
  margin: 0 8px;
  flex: 2;

  ${theme.mediaQueries.medium} {
    flex: initial;
    text-align: center;
  }
`;

export const announcementBannerTitle = css`
  font-weight: 600;

  ${theme.mediaQueries.medium} {
    text-align: initial;
  }
`;

export const announcementBannerSubtitle = css`
  margin-left: 8px;
`;

export const announcementBannerCTA = css`
  white-space: nowrap;
`;
