import { css } from "@emotion/react";
import { Panel, theme } from "@xometry/xometry_loft";
import styled from "@emotion/styled";
import { themeOverrides } from "../../utils/themeOverrides";

const topWrapper = css`
  position: sticky;
  top: 0;
  z-index: 99999;
  overflow-x: hidden;

  [data-testid="topnav-main"] {
    /* Topnav has some arbitrary height set, override */
    height: initial;

    /* Wrapper declares stickiness, 
    so change topnav to not be fixed
    */
    & > div:nth-of-type(1) {
      position: static;

      /** Dropdown menu is hidden 
        * with overflow-x hidden on topWrapper
        * Setting dropdown to fixed makes it visible
      */
      & a[href="#"] + div {
        position: fixed;
      }
    }
  }
`;

const mainGrid = css`
  display: flex;
  width: 100%;
  max-width: 1420px;
  flex-column: column;
  margin: 40px auto 56px;

  ${themeOverrides.mediaQueries.smDesktop} {
    display: block;
  }
`;

const errorWrapper = css`
  display: flex;
  min-height: calc(100vh - 191px - 55px);
  flex-column: column;
  justify-content: center;

  .errorPanel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    border: 1px solid ${theme.colors.black};
    background-color: ${theme.colors.white};
    width: 500px;
    margin-top: ${theme.space[5]};

    .errorText {
      text-align: center;
    }
  }
`;

const leftColumn = css`
  flex: 2;
  flex-grow: 2;
  box-sizing: border-box;

  & > div {
    margin-left: ${theme.space[5]};
    margin-right: ${theme.space[5]};
  }
`;

const rightColumn = css`
  flex: 1;
  flex-grow: 1;
  box-sizing: border-box;

  & > div {
    margin-left: ${theme.space[5]};
    margin-right: ${theme.space[5]};
  }
`;

const welcomeText = css`
  margin-bottom: 40px;
  font-size: 36px;
  line-height: 44px;
  font-weight: 600;
`;

const specialOffersText = css`
  margin-bottom: 18px;
  color: ${theme.colors.grays[0]};
  font-size: 21px;
  line-height: 28px;
  font-weight: 800;
`;

const adsContainer = css`
  border-radius: 3px;
  box-shadow: 0px 1px 4px 0px #c1cad1;
  display: flex;
  flex-direction: column;
  gap: ${theme.space[6]};
  padding: ${theme.space[7]} ${theme.space[5]};
  background-color: ${theme.colors.white};
  margin-bottom: ${theme.space[8]};
`;

const twoColSubgrid = css`
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  & > div {
    flex: 1;

    &:nth-child(odd) {
      padding-right: 12px;
    }

    &:nth-child(even) {
      padding-left: 12px;
    }

    &:last-child {
      padding-right: 0 !important;
    }
  }

  @media (max-width: 550px) {
    display: block;
    & > div {
      &:nth-child(odd) {
        padding-right: 0;
      }

      &:nth-child(even) {
        padding-left: 0;
      }
    }
  }
`;

const subtitle = css`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  color: ${theme.colors.grays[0]};
  font-size: ${theme.fontSizes[2]};
  font-weight: 600;
  padding: 0px 5px;
  margin-bottom: ${theme.space[4]};
  margin-top: ${theme.space[8]};
  .history-links {
    padding-left: 10px;
  }
  a {
    font-size: ${theme.fontSizes[0]};
    font-weight: 600;
    margin: 0px 5px;
  }
`;

const TabContainer = styled(Panel)`
  box-sizing: border-box;
  border: none;
  margin-bottom: ${theme.space[8]};
  box-shadow: 0px 1px 4px 0px #c1cad1;
`;

const tableContainer = css`
  margin-top: ${theme.space[8]};
`;

export {
  topWrapper,
  errorWrapper,
  mainGrid,
  leftColumn,
  rightColumn,
  welcomeText,
  specialOffersText,
  twoColSubgrid,
  subtitle,
  adsContainer,
  TabContainer,
  tableContainer,
};

export default {
  topWrapper,
  mainGrid,
  leftColumn,
  rightColumn,
  welcomeText,
  specialOffersText,
  twoColSubgrid,
};
