import { css } from "@emotion/react";
import { theme } from "@xometry/xometry_loft";

const wrapperDiv = [
  css`
    text-align: center;
    color: ${theme.colors.grays[1]};
    line-height: 1.5;
    width: auto;
    max-width: 1366px;
    margin: 8px 16px;

    @media (min-width: 600px) {
      margin: 20px 16px 0 16px;
    }

    ${theme.mediaQueries.large} {
      margin: 60px auto 0 auto;
      padding: 0 5%;
    }

    .uploadPanel {
      box-sizing: border-box;
      cursor: pointer;
      min-height: 210px;
      border: 1px dashed #1491d0;
      background-color: rgba(20, 145, 208, 0.05);

      &.splitFileUpload {
        padding: 20px;
        border: 1px dashed ${theme.colors.brandBlue};
        background: rgb(250, 252, 254);
        background: linear-gradient(0deg, #e6f0fd 0%, #ffffff 100%);

        :hover {
          background: rgb(243, 248, 254);
          background: linear-gradient(0deg, #dbe9fd 0%, #fafcff 100%);
        }
      }

      h3 {
        font-size: 16px;
        font-weight: ${theme.fontWeights.semibold};
        margin-bottom: 11px;
        margin-top: 0;
      }

      .supportedFilesText {
        font-size: 14px;
        font-weight: ${theme.fontWeights.semibold};
      }

      .fileIcons {
        margin-top: 8px;
        display: flex;
        padding: 0px 12px;
        justify-content: center;
        img {
          height: 16px;

          &.siemens {
            height: 13px;
            padding-top: 2px;
          }

          &:nth-of-type(n + 2) {
            margin-left: 12px;
          }
        }
      }

      button {
        margin-bottom: 8px;
      }
    }
  `,
];

const fileRow = css`
  text-align: left;
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;

  &:hover {
    cursor: default;
  }

  &:first-of-type {
    margin-top: 0;
  }

  &:not(:last-child) {
    margin-bottom: 7.5px;
  }

  &:focus {
    outline: none;
  }

  .fileRowContent {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
    }

    span {
      color: ${theme.colors.grays[1]};
      font-size: 20px;
      font-weight: 500;
      line-height: 20px;
    }

    .fileInfoContainer {
      position: absolute;
      left: 17.5%;
      top: 10%;
      width: 80%;
      display: inline-block;
      flex-flow: row wrap;
      flex-direction: row;
      max-width: calc(100% - 85px);
      align-items: center;
      padding-top: 2px;

      .leftBox {
        float: left;
      }

      .rightBox {
        float: right;
      }

      .fileNameWrapper {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .fileErrorMessage {
        width: 100%;
        font-size: 0.75rem;
      }

      i {
        height: 20px;
        width: 11px;
        color: ${theme.colors.blues[100]};
        font-size: 12px;
        letter-spacing: 0;
        line-height: 20px;
      }

      .delete {
        height: 20px;
        width: 43px;
        color: #0e6cf1;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
      }
    }
  }

  &.rejected {
    background: #f5ebeb;
    border-color: #9e3c3c;
    height: 60px;

    .fileRowContent {
      span,
      i {
        color: #9e3c3c;
      }
    }

    .fileErrorMessage {
      font-size: 10px;
      font-style: italic;
    }
  }
`;

const btnGroup = css`
  button {
    margin-top: 16px;
  }

  button:not(:last-child) {
    margin-right: 8px;
  }
`;

const btnGroupAlt = css`
  button {
    margin: 34px 0 !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  button:not(:last-child) {
    margin-right: 8px !important;
  }
`;

const stepsblock = css`
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  margin-bottom: 50px;

  ${theme.mediaQueries.large} {
    flex-direction: row;
  }
`;

const step = css`
  position: relative;
  flex-basis: 100%;
  flex-grow: 0;
  font-size: ${theme.fontSizes[0]};
  width: 100%;
  margin-bottom: 25px;
  background-color: #f4f7f8;

  svg {
    transform: rotate(90deg);
  }

  svg > line {
    stroke: ${theme.colors.blues[100]};
    stroke-width: 2px;
  }

  &:nth-of-type(2) > .stepIcon > img {
    width: 47px;
  }

  ${theme.mediaQueries.small} {
    font-size: ${theme.fontSizes[1]};
  }

  ${theme.mediaQueries.large} {
    margin-bottom: 60px;
    background: none;

    svg {
      display: none;
    }

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      border-bottom: 2px dashed ${theme.colors.blues[100]};
      right: -50%;
      top: 60px;
      border-radius: 0;
      z-index: -1;
    }

    &:nth-last-of-type(1):after {
      width: 0%;
      height: 0%;
    }

    &:nth-of-type(2) > .stepIcon > img {
      width: 57px;
    }
  }

  .stepIcon {
    background: #eceff1;
    width: 86px;
    height: 86px;
    border-radius: 50px;
    border: 2px solid #eceff1;
    font-size: 0;
    position: relative;
    margin: 10px auto;
    box-sizing: border-box;
    display: flex;

    img {
      width: 50px;
      margin: auto;
    }

    ${theme.mediaQueries.large} {
      width: 100px;
      height: 100px;

      img {
        width: 60px;
      }
    }
  }

  .stepcontenttitle {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 10px;
    color: ${theme.colors.darkBlues[75]};

    @media (min-width: 410px) {
      width: 80%;
    }
  }

  .steptitle {
    font-weight: ${theme.fontWeights.semibold};
    font-size: ${theme.fontSizes[2]};
    color: ${theme.colors.darkBlues[100]};
    margin-bottom: -10px;
  }
`;

export const uploadTreatment = css`
  color: ${theme.colors.darkBlues[100]};
  margin: -10px -${theme.space[5]} ${theme.space[1]} -${theme.space[5]};
  padding: ${theme.space[0]} ${theme.space[4]};

  .confidenceGroup {
    display: none;
  }

  .exampleParts {
    margin-bottom: ${theme.space[10]};
  }

  .exampleParts > img {
    height: 64px;
    margin: ${theme.space[10]} ${theme.space[0]} ${theme.space[0]}
      ${theme.space[10]};

    &:first-of-type {
      margin-left: ${theme.space[0]};
    }

    ${theme.mediaQueries.small} {
      height: 87px;
      margin: 30px ${theme.space[0]} ${theme.space[0]} 30px;
    }
    ${theme.mediaQueries.medium} {
      height: 110px;
      margin: ${theme.space[10]} ${theme.space[0]} ${theme.space[0]}
        ${theme.space[10]};
    }
  }

  h2 {
    font-size: ${theme.fontSizes[4]};
    font-weight: ${theme.fontWeights.semibold};
    margin: ${theme.space[5]} ${theme.space[4]} ${theme.space[2]}
      ${theme.space[4]};
  }

  h3 {
    font-size: ${theme.fontSizes[2]};
    font-weight: ${theme.fontWeights.semibold};
    color: ${theme.colors.darkBlues[90]};
    margin: ${theme.space[2]};

    @media (min-width: 786px) {
      font-size: ${theme.fontSizes[3]};
    }
  }

  .supportedFilesList {
    font-size: 12px;
    font-weight: ${theme.fontWeights.semibold};
    color: ${theme.colors.darkBlues[75]};

    @media (min-width: 786px) {
      font-size: ${theme.fontSizes[2]};
    }
  }
`;

export const confidenceWrapper = css`
  margin: 10px ${theme.space[0]} 5px ${theme.space[0]};
  font-size: ${theme.fontSizes[0]};
  color: ${theme.colors.darkBlues[50]};
  flex-flow: column;
  min-height: 20px;

  .confidenceRight > span {
    margin-left: ${theme.space[2]};
  }

  ${theme.mediaQueries.medium} {
    .confidenceLeft {
      float: left;
    }
    .confidenceRight {
      float: right;
    }
  }

  @media (max-width: 832px) {
    display: flex;
    flex-direction: column;

    .confidenceRight > span {
      margin-top: 7px;
    }

    .confidenceCenter {
      margin-top: 7px;
    }

    .confidenceRight {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  @media (min-width: 832px) {
    display: grid;
    grid-template-columns: 33.3333% 33.3333% 33.3333%;
  }

  ${theme.mediaQueries.large} {
    font-size: ${theme.fontSizes[1]};
    margin: 10px ${theme.space[0]} 70px ${theme.space[0]};
  }
`;

export { wrapperDiv, fileRow, btnGroup, btnGroupAlt, stepsblock, step };
